import React, { useState, useEffect } from 'react';
import './PCI.css'; // Importando o arquivo de estilos CSS
import Joyride from 'react-joyride';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MdMenu, MdBolt, MdBook } from "react-icons/md";
import { ChakraProvider, Center, Divider } from "@chakra-ui/react";
import CustomDrawer from './CustomDrawer';
import translations from '../utils/translations.json';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ReactMarkdown from 'react-markdown';

SwiperCore.use([Navigation, Pagination]);

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const UserProfileImage = ({ userId, perfilPicture }) => {
  const defaultAvatarUrl = `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`;
  const imageUrl = isValidUrl(perfilPicture) ? perfilPicture : defaultAvatarUrl;
  return (
    <div className="user-profile-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
  );
};

const UserInfo = ({ userName, userId, userCredits }) => {
  const handleClick = () => {
    window.open('/viewuser', '_self');
  };
  return (
    <div className="user-info" onClick={handleClick}>
      <UserProfileImage userId={userId} perfilPicture={localStorage.getItem('perfil_picture')} />
      <div className="user-credits">
        {userName} | {userCredits} {t('pci', 'credits')}
      </div>
      <div className="userInfoMobile">
        {userCredits} {t('pci', 'credits')}
      </div>
    </div>
  );
};

let t;

const PCI = () => {



  const [summary, setSummary] = useState('');
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('username');


  const [totenId, setTotenId] = useState(localStorage.getItem('totenId'));
  // let totenId = localStorage.getItem('totenId');
  const [response, setResponse] = useState('');
  const perfilPicture = localStorage.getItem('perfil_picture')
  const defaultImageUrl = "https://blob01instasuccess.blob.core.windows.net/fotos-logos/icone-04.png";
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    targetAudience: '',
    problem: '',
    solution: '',
    benefit: '',
    objection: ''
  });

  const [initialFormData, setInitialFormData] = useState({
    targetAudience: '',
    problem: '',
    solution: '',
    benefit: '',
    objection: ''
  });

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');

  t = (screen, key) => translations[language][screen][key];

  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingPCI, setIsLoadingPCI] = useState(false);
  const [swiperRef, setSwiperRef] = useState(null);

  const handleHamburguerClick = () => {
    setIsOpen(true); // Abre o Drawer
  };

  const handleCloseDrawer = () => {
    setIsOpen(false); // Fecha o Drawer
  };

  const mainOpen = (language) => {

    navigate(`/main?lang=${language}`);
  }

  const perfilOpen = () => {
   
    navigate(`/viewuser?lang=${language}`);
  }

  const analysisOpen = () => {
    
    navigate(`/analysis?lang=${language}`);
  }

  const historyOpen = (language) => {
    
    navigate(`/history?lang=${language}`);
  }

  const logout = (language) => {
   
    navigate(`/login?lang=${language}`);
  }

  const getCreditsSum = () => {
    const creditsString = localStorage.getItem('credits');
    const credits = creditsString ? JSON.parse(creditsString) : { "GPT-3": 0, "GPT-4": 0 };
    return (credits["GPT-3"] || 0) + (credits["GPT-4"] || 0);
  };

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'language') {
        setLanguage(localStorage.getItem('language') || 'pt');
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  const [runJoyride, setRunJoyride] = useState(false);
  const [joyrideSteps, setJoyrideSteps] = useState([
    {
      target: '.target-audience',
      content: t('pci', 'joyrideSteps')[0].content,
    },
    {
      target: '.problem',
      content: t('pci', 'joyrideSteps')[1].content,
    },
    {
      target: '.solution',
      content: t('pci', 'joyrideSteps')[2].content,
    },
    {
      target: '.benefit',
      content: t('pci', 'joyrideSteps')[3].content,
    },
    {
      target: '.objection',
      content: t('pci', 'joyrideSteps')[4].content,
    },
    {
      target: '.btnaction',
      content: t('pci', 'joyrideSteps')[5].content,
    }
  ]);


  useEffect(() => {
      if (localStorage.getItem('firstAccess') === 'true') {
          setRunJoyride(true);
      }
  }, []);


  async function confirmSubmit() {
    // Lógica para enviar os dados e obter o resultado
    setTimeout(() => {
      if (swiperRef) {
        swiperRef.slideNext(1000);
      }
    }, 100);
  }

  const [userCredits, setUserCredits] = useState(getCreditsSum());



  const fetchPCI = async () => {

    //const perfil_picture = `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl/fetch`, {
        userId: userId,
        totenId: totenId,
        perfil_picture: `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`
      });

      if (response.status !== 200) {

        throw new Error(t('pci', 'fetchDataError'));

      }

      const pciData = response.data.pciData;

      setTotenId(response.data.totenId);
      localStorage.setItem('totenId', response.data.totenId);

      if (pciData) {

        setFormData({
          targetAudience: pciData.targetAudience,
          problem: pciData.problem,
          solution: pciData.solution,
          benefit: pciData.benefit,
          objection: pciData.objection
        });
        setInitialFormData({
          targetAudience: pciData.targetAudience,
          problem: pciData.problem,
          solution: pciData.solution,
          benefit: pciData.benefit,
          objection: pciData.objection
        });
      }

    } catch (error) {

      Swal.fire({
        icon: 'error',
        title: t('pci', 'errorTitle'),
        text: error.message,
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        }
      });
    }
  };



  let fetchCalled = 0;

  useEffect(() => {
    if (fetchCalled === 0) {
      fetchPCI();
      fetchCalled++;
    }
  }, [userId, totenId]);


  const handleInputChange = (e, fieldType) => {
    const value = e.target.value;
    setFormData({ ...formData, [fieldType]: value });
  };


  const handleSummaryClick = async () => {
    try {
      // Exibir um popup temporário para indicar que o resumo está sendo carregado
      Swal.fire({
        title: t('pci', 'loadingSummary'),
        text: t('pci', 'waitLoadingSummary'),
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        }
      });

      // Buscar o resumo mais recente do servidor
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl/summary`, {
        userId,
        totenId
      });

      if (response.status !== 200) {
        throw new Error(t('pci', 'summaryFetchError'));
      }

      let summary = response.data.summary;

      setSummary(summary);

      // Fechar o popup de carregamento
      Swal.close();

      // Verifica se o resumo está vazio e exibe um placeholder apropriado
      const finalSummary = summary || t('pci', 'summaryNotAvailable');

      // Exibir o popup com o resumo atualizado
      const { value: editedSummary } = await Swal.fire({
        title: t('pci', 'summary'),
        input: "textarea",
        inputValue: finalSummary,
        inputPlaceholder: t('pci', 'summaryPlaceholder'),
        showCancelButton: true,
        confirmButtonText: t('pci', 'saveSummary'),
        cancelButtonText: t('pci', 'cancel'),
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        },
        didOpen: () => {
          const textarea = Swal.getPopup().querySelector('textarea');
          if (textarea) {
            textarea.selectionStart = 0;
            textarea.selectionEnd = 0;
            textarea.focus();
            textarea.scrollTop = 0;
          }
        }
      });

      if (editedSummary && editedSummary !== finalSummary) {

        setSummary(editedSummary);

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl/updateSummary`, {
          userId,
          totenId,
          summary: editedSummary
        });

        Swal.fire({
          icon: 'success',
          title: t('pci', 'summarySaved'),
          customClass: {
            popup: 'my-swal-alert',
            title: 'myCustomTitle'
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: t('pci', 'errorTitle'),
        text: error.message,
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        }
      });
    }
  };


  // Função para normalizar e ordenar objetos
  const normalizeAndSortObject = (obj) => {
    const sortedObj = {};
    const keys = Object.keys(obj).sort();
    keys.forEach((key) => {
      sortedObj[key] = typeof obj[key] === 'string' ? obj[key].replace(/\s+/g, '').toLowerCase() : obj[key];
    });
    return sortedObj;
  };



  async function confirmSubmit() {
    // Validação dos campos
    if (!formData.targetAudience || !formData.problem || !formData.solution || !formData.benefit || !formData.objection) {
      Swal.fire({
        icon: 'warning',
        title: t('pci', 'fillAllFieldsWarning'),
        text: t('pci', 'fillAllFieldsText'),
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        }
      });
      return;
    }

    // Buscar dados atualizados do banco de dados sem atualizar o estado do formulário
    let updatedData;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl/fetch`, {
        userId,
        totenId,
        perfil_picture: ''
      });

      if (response.status !== 200) {
        throw new Error(t('pci', 'fetchDataError'));
      }

      updatedData = response.data.pciData;

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: t('pci', 'errorTitle'),
        text: error.message,
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        }
      });
      return;
    }

    const normalizedFormData = normalizeAndSortObject(formData);
    const normalizedUpdatedData = updatedData ? normalizeAndSortObject({
      targetAudience: updatedData.targetAudience,
      problem: updatedData.problem,
      solution: updatedData.solution,
      benefit: updatedData.benefit,
      objection: updatedData.objection
    }) : normalizeAndSortObject({
      targetAudience: '',
      problem: '',
      solution: '',
      benefit: '',
      objection: ''
    });

    // Verifica se os dados do formulário foram alterados
    if (JSON.stringify(normalizedFormData) === JSON.stringify(normalizedUpdatedData)) {
      // Os dados não foram alterados, usa os dados do banco de dados
      setResponse(updatedData.pci);
      setSummary(updatedData.brief_pci);

      setTimeout(() => {
        if (swiperRef) {
          swiperRef.slideNext(1000);
        }
      }, 100);
    } else {
      const userChoice = localStorage.getItem('popupChoicePCI');

      if (userChoice === 'checked') {
        handleDataSubmission();
      } else {
        Swal.fire({
          icon: 'warning',
          title: t('pci', 'confirmCreditUsage'),
          showCancelButton: true,
          confirmButtonText: t('pci', 'yes'),
          cancelButtonText: t('pci', 'no'),
          text: t('pci', 'Explanation'),
          input: 'checkbox',
          inputValue: 0,
          inputPlaceholder: t('pci', 'doNotShowAgain'),
          customClass: {
            popup: 'my-swal-alert',
            title: 'myCustomTitle'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value === 1) {
              localStorage.setItem('popupChoicePCI', 'checked');
            }
            handleDataSubmission();
          }
        });
      }
    }
  }

  async function handleDataSubmission() {

    try {

      let firstAccess = false;

      if (localStorage.getItem('firstAccess') !== 'undefined' && localStorage.getItem('firstAccess') === 'true') {

        firstAccess = true;

      }

      const userCredits = JSON.parse(localStorage.getItem('credits')) || { "GPT-3": 0, "GPT-4": 0 };

      if (userCredits["GPT-4"] < 2) {
        Swal.fire({
          icon: 'warning',
          title: t('pci', 'insufficientCreditsWarning'),
          text: t('pci', 'insufficientCreditsText'),
          customClass: {
            popup: 'my-swal-alert',
            title: 'myCustomTitle'
          }
        });
        swiperRef.slideTo(0, 1000);
        return;
      }

      setIsLoadingPCI(true);
      setTimeout(() => {
        if (swiperRef) {
          swiperRef.slideNext(1000);
        }
      }, 100);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl`, {
        userId,
        totenId,
        targetAudience: formData.targetAudience,
        problem: formData.problem,
        solution: formData.solution,
        benefit: formData.benefit,
        objection: formData.objection,
        language: language
      });

      if (response.status !== 200) {

        throw new Error(t('pci', 'summaryFetchError'));

      } else {

        if (firstAccess) {

          setTimeout(() => {
            Swal.fire({
              title: t('pci', 'readyToCreateTitle'),
              text: t('pci', 'readyToCreateText'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: t('pci', 'createNowButton'),
              cancelButtonText: t('pci', 'readPciButton'),
              customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                mainOpen(localStorage.getItem('language'));
              }
            });
          }, 15000);
        }

      }

      const result = response.data;
      setResponse(result.data);

      const newCredits = result.credits;
      localStorage.setItem('credits', JSON.stringify(newCredits));
      setUserCredits(getCreditsSum());
      setIsLoadingPCI(false);


    } catch (error) {
      setIsLoadingPCI(false);
      Swal.fire({
        icon: 'error',
        title: t('pci', 'errorTitle'),
        text: error.response.data.error,
        customClass: {
          popup: 'my-swal-alert',
          title: 'myCustomTitle'
        }
      });
    }
  }


  const customStyles = {
    options: {
      arrowColor: '#8E59FF', // Cor do balão de dicas
      backgroundColor: '#FFFFFF', // Cor de fundo do balão de dicas
      primaryColor: '#8E59FF', // Cor primária usada para o botão de ação
      textColor: '#757575', // Cor do texto
      zIndex: 1000, // Z-index para o elemento sobrepor outros componentes
    },
    buttonNext: {
      backgroundColor: '#8E59FF' // Cor do botão "Próximo"
    },
    buttonBack: {
      backgroundColor: '#CCCCCC',
      color: '#FFFFFF' // Cor do botão "Voltar"
    },
    buttonSkip: {
      backgroundColor: '#CCCCCC',
      color: '#FFFFFF' // Cor do botão "Pular"
    }
  };

  return (
    <>
      <Joyride
        run={runJoyride}
        steps={joyrideSteps}
        styles={customStyles}
        continuous={true}
        showSkipButton={true}

        locale={{
          skip: t('pci', 'joyrideLabelsSkip'), next: t('pci', 'joyrideLabelsNext'),
          back: t('pci', 'joyrideLabelsBack'), last: t('pci', 'joyrideLabelsLast')
        }}

        callback={(data) => {
          if (data.status === 'finished' || data.status === 'skipped') {
            setRunJoyride(false);
          }
        }}
      />
      <Swiper
        onSwiper={(swiper) => setSwiperRef(swiper)}
        onSlideChange={(swiper) => { }}
        navigation
        pagination={{ clickable: true }}
        className="mySwiper"
        slidesPerView={1}
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
          "--swiper-navigation-size": "20px"
        }}
      >
        <SwiperSlide>
          <div className="pci-container">
            <ChakraProvider>
              <CustomDrawer
                isOpen={isOpen}
                onClose={handleCloseDrawer}
                onContentOpen={() => mainOpen(language)}
                onPerfilOpen={() => perfilOpen(language)}
                onAnalysisOpen={() => analysisOpen(language)}
                onLogout={() => logout(language)}
                onHistoryOpen={() => historyOpen(language)}
                t={t}
                language={language}
                setLanguage={setLanguage}
              />
            </ChakraProvider>
            <div className="header">
              <UserInfo userName={userName} userId={userId} userCredits={userCredits} />
              <div className="image-container_pci">
                <MdMenu
                  className="menu-icon"
                  onClick={handleHamburguerClick}
                />
              </div>
              <div className="image-container_pci_02">
                <img
                  src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                  alt="Logo"
                  className="logo"
                />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'auto', width: '100%' }}>
              <div className="publicContainer_pci" >
                <div className='divTitle' style={{ display: 'flex', marginLeft: 10 }}>
                  <label className="categoryTitle-boxpci">
                    {t('pci', 'definingIdealCustomerProfile')}
                  </label>
                </div>
                <label className="labelTopic target-audience" style={{ color: '#757575', marginLeft: 10 }}>
                  {t('pci', 'targetAudience')}
                  <input
                    type="text"
                    onChange={(e) => handleInputChange(e, 'targetAudience')}
                    value={formData.targetAudience}
                    className='inputFocus_pci'
                    placeholder={t('pci', 'audiencePlaceholder')}
                  />
                </label>
                <ChakraProvider>
                  <Center width='97%' display={{ base: 'none', md: 'flex' }}>
                    <Divider className="dividerFirst" />
                  </Center>
                </ChakraProvider>
                <div className='divAjust'>
                  <label className="labelTopic problem" style={{ color: '#757575', marginLeft: 10 }}>
                    {t('pci', 'problem')}
                    <input
                      type="text"
                      className='inputFocus_pci_02'
                      onChange={(e) => handleInputChange(e, 'problem')}
                      value={formData.problem}
                      placeholder={t('pci', 'problemPlaceholder')}
                    />
                  </label>
                  <label className="labelTopic solution" style={{ color: '#757575', marginLeft: 10 }}>
                    {t('pci', 'solution')}
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, 'solution')}
                      value={formData.solution}
                      className='inputFocus_pci_02'
                      placeholder={t('pci', 'solutionPlaceholder')}//{t('main', 'placeholderAudienceExamples')}
                    />
                  </label>
                </div>
                <ChakraProvider>
                  <Center width='97%' display={{ base: 'none', md: 'flex' }} >
                    <Divider className="dividerFirst" />
                  </Center>
                </ChakraProvider>
                <div className='divAjust_2'>
                  <label className="labelTopic benefit" style={{ color: '#757575', marginLeft: 10 }}>
                    {t('pci', 'benefit')}
                    <input
                      type="text"
                      className='inputFocus_pci_02'
                      onChange={(e) => handleInputChange(e, 'benefit')}
                      value={formData.benefit}
                      placeholder={t('pci', 'benefitPlaceholder')}
                    />
                  </label>
                  <label className="labelTopic objection" style={{ color: '#757575', marginLeft: 10 }}>
                    {t('pci', 'objection')}
                    <input
                      type="text"
                      onChange={(e) => handleInputChange(e, 'objection')}
                      value={formData.objection}
                      className='inputFocus_pci_02'
                      placeholder={t('pci', 'objectionPlaceholder')}//{t('main', 'placeholderAudienceExamples')}
                    />
                  </label>
                </div>

                <div className='divbtnPCI'>
                  <button
                    style={{ backgroundColor: '#8E59FF', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%' }}
                    className='googleButtonNew btnaction'
                    onClick={confirmSubmit}
                  >
                    <MdBolt className="buttonIcon" />
                    <span className="buttonContent" >{t('pci', 'sendData')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {isLoadingPCI ? (
          <SwiperSlide className="blink">{t('pci', 'loadingProfile')}</SwiperSlide>
        ) : (
          response.length > 0 && (
            <SwiperSlide>
              <div className="header">
                <UserInfo userName={userName} userId={userId} userCredits={userCredits} />
                <div className="image-container_pci">
                  <MdMenu
                    className="menu-icon"
                    onClick={handleHamburguerClick}
                  />
                </div>
                <div className="image-container_pci_02">
                  <img
                    src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                    alt="Logo"
                    className="logo"
                  />
                </div>
              </div>
              <div className="divTextAreaPCI">
                <ReactMarkdown className="markdownSpace custom-markdown" children={response} />
                <div className="buttonGroupPCI">
                  {/* <button className="googleButton buttonStyle" onClick={handleSummaryClick}>Resumo</button> */}
                  <button className="googleButtonNew" style={{ backgroundColor: '#8E59FF', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleSummaryClick}> <MdBook className="buttonIcon" /> <span className="buttonContent" >{t('pci', 'summary')}</span></button>
                </div>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </>
  );
};

export default PCI;