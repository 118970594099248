// CanvaContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios';

const CanvaContext = createContext();

export function CanvaProvider({ children }) {
    const [isCanvaConnected, setIsCanvaConnected] = useState(false);

    const checkCanvaConnection = useCallback(async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-canva-token`, {
                userId
            });
            setIsCanvaConnected(response.data.success);
            return response.data.success;
        } catch (error) {
            console.error('Erro ao validar token do Canva:', error);
            setIsCanvaConnected(false);
            return false;
        }
    }, []);

    const disconnectCanva = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/canva-delete-session`, {
                userId
            });
            if (response.data.success) {
                setIsCanvaConnected(false);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Erro ao desconectar Canva:', error);
            return false;
        }
    }, []);

    return (
        <CanvaContext.Provider value={{
            isCanvaConnected,
            setIsCanvaConnected,
            checkCanvaConnection,
            disconnectCanva
        }}>
            {children}
        </CanvaContext.Provider>
    );
}

export function useCanva() {
    return useContext(CanvaContext);
}