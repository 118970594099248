import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import MainScreen from './components/MainScreen';
import PCI from './components/PCI';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPassword from './components/ResetPassword';
import UserForm from './components/UserForm';
import ControlPanel from './components/ControlPanel';
import LandscapeWarning from './components/LandscapeWarning';
import ContentAnalysis from './components/ContentAnalysis';
import HistoryScreen from './components/HistoryScreen';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsService from './components/TermsService';
import LogoScreen from './components/LogoScreen';
import { CanvaProvider } from './components/CanvaContext';
import ChatbotController from './components/ChatbotController';
import { ToastContainer } from 'react-toastify';


function App() {

  return (
    <>
      <LandscapeWarning />
      <ToastContainer />
      <Router>
      <CanvaProvider>
      <ChatbotController />
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/analysis" element={<ContentAnalysis />} />
          <Route path="/history" element={<HistoryScreen />} />
          <Route path="/main" element={<MainScreen />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsservice" element={<TermsService />} />
          <Route path="/logoscreen" element={<LogoScreen />} />
          <Route path="/pci" element={<PCI />} />
          <Route path="/resetpasswordrequest" element={<ResetPasswordRequest />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/register" element={<UserForm />} />
          <Route path="/viewuser" element={<UserForm mode="view" />} />
          <Route path="/controlpanel/:userId" element={<ControlPanel />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
        </CanvaProvider>
      </Router>
    </>
  );
}

export default App;