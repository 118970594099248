const fontList = [
    { name: '', value: '' },
    { name: 'Roboto', value: 'Roboto' },
    { name: 'Inter', value: 'Inter' },
    { name: 'Open Sans', value: 'Open Sans' },
    { name: 'Lato', value: 'Lato' },
    { name: 'Slabo 27px', value: 'Slabo 27px' },
    { name: 'Oswald', value: 'Oswald' },
    { name: 'Source Sans Pro', value: 'Source Sans Pro' },
    { name: 'Montserrat', value: 'Montserrat' },
    { name: 'Raleway', value: 'Raleway' },
    { name: 'PT Sans', value: 'PT Sans' },
    { name: 'Roboto Condensed', value: 'Roboto Condensed' },
    { name: 'Ubuntu', value: 'Ubuntu' },
    { name: 'Droid Sans', value: 'Droid Sans' },
    { name: 'Arimo', value: 'Arimo' },
    { name: 'Merriweather', value: 'Merriweather' },
    { name: 'PT Serif', value: 'PT Serif' },
    { name: 'Playfair Display', value: 'Playfair Display' },
    { name: 'Indie Flower', value: 'Indie Flower' },
    { name: 'Bitter', value: 'Bitter' },
    { name: 'Noto Sans', value: 'Noto Sans' },
    { name: 'Fira Sans', value: 'Fira Sans' },
    { name: 'Poppins', value: 'Poppins' },
    { name: 'Karla', value: 'Karla' },
    { name: 'Barlow', value: 'Barlow' },
    { name: 'Varela Round', value: 'Varela Round' },
    { name: 'Nunito', value: 'Nunito' },
    { name: 'Work Sans', value: 'Work Sans' },
    { name: 'Titillium Web', value: 'Titillium Web' },
    { name: 'Heebo', value: 'Heebo' },
    { name: 'Mulish', value: 'Mulish' },
    { name: 'Quicksand', value: 'Quicksand' },
    { name: 'Asap', value: 'Asap' },
    { name: 'Overpass', value: 'Overpass' },
    { name: 'Abril Fatface', value: 'Abril Fatface' },
    { name: 'Exo 2', value: 'Exo 2' },
    { name: 'Inconsolata', value: 'Inconsolata' },
    { name: 'Josefin Sans', value: 'Josefin Sans' },
    { name: 'Anton', value: 'Anton' },
    { name: 'Comfortaa', value: 'Comfortaa' },
    { name: 'Pacifico', value: 'Pacifico' },
    { name: 'Amatic SC', value: 'Amatic SC' },
    { name: 'Orbitron', value: 'Orbitron' },
    { name: 'Bree Serif', value: 'Bree Serif' },
    { name: 'Concert One', value: 'Concert One' },
    { name: 'Yanone Kaffeesatz', value: 'Yanone Kaffeesatz' }
];


export default fontList 