import React, { useState, useEffect } from 'react';
import './ContentAnalysis.css'; // Importando o arquivo de estilos CSS
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { MdMenu, MdBolt } from "react-icons/md";
import { ChakraProvider } from "@chakra-ui/react";
import CustomDrawer from './CustomDrawer';
import translations from '../utils/translations.json';
import { useLocation } from 'react-router-dom';


const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }
    return true;
};


const UserProfileImage = ({ userId, perfilPicture }) => {
    const defaultAvatarUrl = `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`;
    const imageUrl = isValidUrl(perfilPicture) ? perfilPicture : defaultAvatarUrl;
    return (
        <div className="user-profile-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
    );
};

const UserInfo = ({ userName, userId, userCredits }) => {
    const handleClick = () => {
        window.open('/viewuser', '_self');
    };
    return (
        <div className="user-info" onClick={handleClick}>
            <UserProfileImage userId={userId} perfilPicture={localStorage.getItem('perfil_picture')} />
            <div className="user-credits">
                {userName} | {userCredits} {t('analysis', 'credits')}
            </div>
            <div className="userInfoMobile">
                {userCredits} {t('analysis', 'credits')}
            </div>
        </div>
    );
};

let t;

const ContentAnalysis = () => {

    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('username');
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [postUrl, setPostUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const defaultImageUrl = "https://blob01instasuccess.blob.core.windows.net/fotos-logos/icone-04.png";
    const navigate = useNavigate();
    const [isGPT4Enabled, setIsGPT4Enabled] = useState(true)

    const getCreditsSum = () => {
        const creditsString = localStorage.getItem('credits');
        const credits = creditsString ? JSON.parse(creditsString) : { "GPT-3": 0, "GPT-4": 0 };
        return (credits["GPT-3"] || 0) + (credits["GPT-4"] || 0);
    };


    const [userCredits, setUserCredits] = useState(getCreditsSum());

    const [isOpen, setIsOpen] = useState(false);

    const location = useLocation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');

    /*  const handleChange = (event) => {
          const isGPT4Enabled = event.target.checked;
          setIsGPT4Enabled(isGPT4Enabled);
          localStorage.setItem('isGPT4Enabled', isGPT4Enabled.toString());
      }; */


    /*  useEffect(() => {
        const params = new URLSearchParams(location.search);
        const langFromUrl = params.get('lang');
        if (langFromUrl && langFromUrl !== language) {
            setLanguage(langFromUrl);
            localStorage.setItem('language', langFromUrl);
        }
    }, [location, language]); */

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'language') {
                setLanguage(localStorage.getItem('language') || 'pt');
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);



    t = (screen, key) => translations[language][screen][key];


    const handleHamburguerClick = () => {
        setIsOpen(true); // Abre o Drawer
    };

    const handleCloseDrawer = () => {
        setIsOpen(false); // Fecha o Drawer
    };


    const getImageStyle = () => {
        if (!imageUrl && !isLoading) {
            // Estilo para a imagem padrão
            return { height: '310px', objectFit: 'cover' }; // Ajuste a altura conforme necessário
        } else {
            // Estilo para a imagem do Instagram
            return { height: 'auto' };
        }
    };

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'credits') {
                setUserCredits(parseInt(localStorage.getItem('credits')) || 0);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleInputChange = (e) => {
        let url = e.target.value;
        url = url.split('?')[0];
        setInputValue(url);
    };

    const mainOpen = (language) => {
        navigate(`/main?lang=${language}`);
    }

    const pciOpen = (language) => {
        navigate(`/pci?lang=${language}`);
    }

    const perfilOpen = (language) => {
        navigate(`/viewuser?lang=${language}`);
    }

    const historyOpen = (language) => {
        navigate(`/history?lang=${language}`);
    }

    const logout = (language) => {
        navigate(`/login?lang=${language}`);
    }

    const handleButtonClick = (event) => {
        event.preventDefault();

        // Verifica se o campo está vazio
        if (!inputValue.trim()) {
            Swal.fire({
                title: t('analysis', 'emptyField'),
                text: t('analysis', 'insertInstagramLink'),
                icon: 'warning',
                confirmButtonText: 'Ok',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            }).then(() => {
                setInputValue('');
            });
        } else {
            // Expressão regular para validar o link de postagem do Instagram
            const regex = /^https?:\/\/(www\.)?instagram\.com\/p\/[a-zA-Z0-9_-]+\/?$/;
            if (!regex.test(inputValue)) {
                Swal.fire({
                    title: t('analysis', 'invalidUrl'),
                    text: t('analysis', 'validInstagramLink'),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                }).then(() => {
                    setInputValue('');
                });
            } else {

                const userChoice = localStorage.getItem('popupAnalysis');


                const userCredits = JSON.parse(localStorage.getItem('credits')) || { "GPT-3": 0, "GPT-4": 0 };

                if (userCredits["GPT-4"] < 2) {
                    Swal.fire({
                        icon: 'warning',
                        title: t('analysis', 'insufficientCreditsWarning'),
                        text: t('analysis', 'insufficientCreditsText'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });

                    return;
                }

                if (userChoice === 'checked') {

                    Swal.fire({
                        title: t('analysis', 'success'),
                        text: t('analysis', 'linkSent'),
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    }).then(() => {
                        scrapeInstagramData(inputValue);
                        setInputValue('');
                    });

                } else {

                    Swal.fire({
                        title: t('analysis', 'confirmCreditUse'),
                        showCancelButton: true,
                        confirmButtonText: t('analysis', 'yes'),
                        cancelButtonText: t('analysis', 'no'),
                        input: 'checkbox',
                        inputValue: 0,
                        inputPlaceholder: t('analysis', 'doNotShowAgain'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // E adicionamos a verificação aqui
                            if (result.value === 1) {
                                localStorage.setItem('popupAnalysis', 'checked');
                            }

                            Swal.fire({
                                title: t('analysis', 'success'),
                                text: t('analysis', 'videoNotSupported'),
                                icon: 'success',
                                confirmButtonText: 'Ok',
                                customClass: {
                                    popup: 'my-swal-alert',
                                    title: 'myCustomTitle'
                                }
                            }).then(() => {
                                scrapeInstagramData(inputValue);
                                setInputValue('');
                            });

                        }

                    })
                }
            }
        }
    };


    const scrapeInstagramData = async (value) => {
        setIsLoading(true);
        try {

            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/analysis/scrapeInstagram', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                    value: value,
                    gpt4: isGPT4Enabled,
                    audience: '',
                    language: language
                }),
            });

            const data = await response.json();

            if (data.error) {
                throw new Error(data.error);
            }

            const responseFormatted = data.responses.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n\n/g, '<br/><br/>').replace('Grande Ideia', 'Big Idea');
            setResponse(responseFormatted);
            setImageUrl(data.firstImageUrl);
            setPostUrl(value);
            setIsLoading(false);

            const newCredits = data.credits;
            localStorage.setItem('credits', JSON.stringify(newCredits));
            setUserCredits(getCreditsSum);


        } catch (error) {
            console.error('There was an error!', error);
            setIsLoading(false);
            setResponse('');
            setImageUrl('');
            if (error.message.includes("Vídeos não são suportados no momento.")) {
                Swal.fire({
                    icon: 'warning',
                    title: t('analysis', 'attention'),
                    text: t('analysis', 'videoNotSupported'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t('analysis', 'errorTitle'),
                    text: t('analysis', 'serverError'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }
        }
    };

    return (

        <div className="content-analysis">
            <ChakraProvider>
                <CustomDrawer
                    isOpen={isOpen}
                    onClose={handleCloseDrawer}
                    onContentOpen={() => mainOpen(language)}
                    onPerfilOpen={() => perfilOpen(language)}
                    onLogout={() => logout(language)}
                    onHistoryOpen={() => historyOpen(language)}
                    onPCI={() => pciOpen(language)}
                    t={t}
                    language={language}
                    setLanguage={setLanguage}
                />
            </ChakraProvider>
            <div className="header">
                <UserInfo userName={userName} userId={userId} userCredits={userCredits} />
                <div className="image-container_01">
                    <MdMenu
                        className="menu-icon"
                        onClick={handleHamburguerClick}
                    />
                </div>
                <div className="image-container_02">
                    <img
                        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                        alt="Logo"
                        className="logo"
                    />
                </div>
            </div>
            <div className='insideItens'>
                <h2 className='screenTitle' style={{ color: 'grey' }}> {t('analysis', 'analyzeContent')}</h2>
                <div className="container_02">
                    <div className="form-container-padding_02">
                        <form >
                            <label className='labelFont'>
                                {t('analysis', 'insertPostLink')}
                            </label>
                            <div className="form-row_02">
                                <input type="text" onChange={handleInputChange} value={inputValue} placeholder={t('analysis', 'example')} />
                                <button style={{ backgroundColor: '#8E59FF', color: 'white' }} className="submit-button_02" onClick={handleButtonClick}>
                                    <MdBolt className="buttonIcon_02" />
                                    <span className="text-mobile">{t('analysis', 'sendMobile')}</span>
                                    <span className="text-desktop">  {t('analysis', 'sendDesktop')} </span> </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="response-section_02">
                    <div className="response-section-padding_02">
                        <label className='labelFont-02'> {t('analysis', 'analyzedPost')}  </label>
                        <div className="response-row_02">
                            {isLoading ? (
                                <div className="image-loader">
                                    <div className="spinner_01"></div>
                                </div>
                            ) : (
                                imageUrl && imageUrl !== defaultImageUrl ? (
                                    <a href={postUrl} target="_blank" rel="noopener noreferrer" className='linkbox'>
                                        <img
                                            src={imageUrl}
                                            alt="Instagram Post"
                                            className="response-image_02"
                                            style={getImageStyle()}
                                        />
                                    </a>
                                ) : (
                                    <img
                                        src={defaultImageUrl}
                                        alt="Instagram Post"
                                        className="response-image-default"
                                        style={getImageStyle()}
                                    />
                                )
                            )}
                            <div
                                className={isLoading ? 'txtArea loading-text blinking-text' : 'txtArea'}
                                dangerouslySetInnerHTML={{ __html: isLoading ? t('analysis', 'loadingAnalysis') : response }}
                                readOnly
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default ContentAnalysis;
