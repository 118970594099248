import React, {useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ResetPassword.css';
import Swal from 'sweetalert2';
//import 'sweetalert2/src/sweetalert2.scss'
import translations from '../utils/translations.json';
import { useLocation } from 'react-router-dom';
import '@sweetalert2/theme-material-ui/material-ui.css';


const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { token } = useParams();

    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const langFromUrl = params.get('lang');
        if (langFromUrl && langFromUrl !== language) {
            setLanguage(langFromUrl);
            localStorage.setItem('language', langFromUrl);
        }
    }, [location, language]);

    const t = (screen, key) => translations[language][screen][key];

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            Swal.fire({
                title: t('resetPassword', 'error'),
                text: t('resetPassword', 'passwordsDoNotMatch'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
            return;
        }

        console.log('API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
        console.log('token:', token);

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/account/changepassword`, { token, password })
        .then(response => {
            console.log(response);
            Swal.fire({
                title: t('resetPassword', 'successTitle'),
                text: t('resetPassword', 'passwordResetSuccess'),
                icon: 'success',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            }).then(result => {
                if (result.isConfirmed) {
                    window.location.href = `/login?lang=${language}`;
                }
            })
        })
            .catch(error => {
                console.error(error);
                Swal.fire({
                    title: t('resetPassword', 'error'),
                    text: t('resetPassword', 'passwordResetError'),
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            });
    }

    function handlePasswordChange(newValue, setPasswordFunction) {
        if (newValue.length <= 12) {
            setPasswordFunction(newValue);
        } else {
          Swal.fire({
            icon: 'warning',
            title: t('profile', 'characterLimitExceeded'),
            text: t('profile', 'passwordCharacterLimit'),
            customClass: {
              popup: 'my-swal-alert',
              title: 'myCustomTitle'
            }
          });
        }
      }

    return (
        <div className="reset-password">
           <div className="logo-container">
                    <img
                        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                        alt="Logo"
                        className="logo-image"
                    />
                </div>
            <form onSubmit={handleSubmit}>
                <label style={{color: '#757575'}}>
                {t('resetPassword', 'newPassword')}
                    <input 
                        type="password" 
                        value={password} 
                        className="textImput" 
                        onChange={e => handlePasswordChange(e.target.value, setPassword)} 
                    />
                </label>
                <label style={{color: '#757575'}}>
                {t('resetPassword', 'confirmNewPassword')}
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        className="textImput" 
                        onChange={e => handlePasswordChange(e.target.value, setConfirmPassword)} 
                    />
                </label>
                <input className="submit-button" type="submit" value={t('resetPassword', 'changePassword')} />
            </form>
        </div>
    );
}

export default ResetPassword;
