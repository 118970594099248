export const Info_1 = 'Na construção ou edição dos prompts, fique atento à lista de marcadores. Eles serão substituídos pelas seguintes correspondências:\n\n${audience}: Substitui Público Alvo.\n\n${niche}: Substitui Nicho.\n\n${anxiety}: Substitui Medos & Ansiedades.\n\n${headline}: Substitui Headline.\n\n${archetype}: Substitui Arquétipo.\n\n${stack}: Substitui Acervo.';
export const Info_2 = '"Ansiedade" e "Imagem" geram respostas independentes. "Headline" depende de "Ansiedade" e "Carrossel" depende de "Headline". Portanto, defina "Ansiedade" para "Headline" e "Headline" para "Carrossel".';
export const Info_3 = 'Ao concatenar "Headline" com "Carrossel", a ferramenta, por padrão, sempre escolhe a terceira "Headline", pois a opção "Escolha a Headline" não está disponível na tela de testes.';
export const Info_4 = 'Na saída do prompt de carrossel, a criação de capa ocorre automaticamente se em sua resposta o pedido de figura for precedido por "imagem" ou "image". Quando isso ocorrer, apenas o "user" será usado para otimizar a imagem, enquanto o "system" será desconsiderado.';
export const Info_5 = 'A "sensibilidade" determina a liberdade criativa da inteligência artificial. Enquanto "1" permite uma geração totalmente livre e imprevisível, "0" estabelece a máxima restrição, tornando a IA mais focada e consistente.';
export const Info_6 = "O botão 'Teste' permite que você teste o prompt que foi criado aqui na tela principal do programa. Se você estiver satisfeito com o ajuste e quiser oficializar o prompt, basta clicar em 'Aprovado'. Observe que não é possível excluir um prompt. Portanto, se você não quiser mais utilizá-lo, basta editá-lo para gerar um novo.";
export const Info_7 = "Não se preocupe, o teste que você realiza na tela principal só aparecerá para você. Todos os outros usuários continuarão trabalhando com o prompt padrão do momento.";
export const Info_8 = "Clique para enviar uma foto. A imagem deve ser quadrada e ter no máximo 1MB.";
export const Info_9 = "O texto no carrossel aparece à esquerda. Se o principal elemento da foto estiver do lado esquerdo, considere inverter a imagem para melhor visualização."
export const Info_10 = "Elegante em sua simplicidade, este estilo é projetado para comunicação direta. É ideal para quem prefere um design limpo ou tem conhecimento limitado em design.";
export const Info_11 = "Inspirado pelas ferramentas visuais típicas dos Stories do Instagram, este estilo é associado a um alto engajamento. É perfeito para quem tem conhecimento básico em design.";
export const Info_12 = "Uma abordagem criativa que dá ênfase ao destaque visual. Recomendado para quem tem habilidade em design. Se você não estiver confiante, considere outras opções.";
export const Info_13 = "Você voltará ao começo do processo e tudo o que foi feito, inclusive os créditos, será perdido. Deseja continuar?";
export const Info_14 = "Parece que o processo levou mais tempo do que o esperado. Você gostaria de tentar novamente ou retornar ao início? Se você escolher voltar ao início, os créditos gastos serão estornados.";
