import React, { useState, useEffect, useRef } from 'react';
import { MdMenu, MdEdit, MdKeyboard, MdDateRange, MdAdb } from "react-icons/md";
import { ChakraProvider, Select, RadioGroup, Stack, useMediaQuery, Radio } from "@chakra-ui/react";
import CustomDrawer from './CustomDrawer';
import Swal from 'sweetalert2';
import translations from '../utils/translations.json';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import './HistoryScreen.css'; // Importando o arquivo de estilos CSS
import { useLocation } from 'react-router-dom';

const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }
    return true;
};

const UserProfileImage = ({ userId, perfilPicture }) => {
    const defaultAvatarUrl = `https://example.com/avatar/${userId}`;
    const imageUrl = isValidUrl(perfilPicture) ? perfilPicture : defaultAvatarUrl;
    return (
        <div className="user-profile-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
    );
};

const UserInfo = ({ userName, userId, userCredits }) => {
    const handleClick = () => {
        window.open('/viewuser', "_blank");
    };
    return (
        <div className="user-info" onClick={handleClick}>
            <UserProfileImage userId={userId} perfilPicture={localStorage.getItem('perfil_picture')} />
            <div className="user-credits">
                {userName} | {userCredits} {t('history', 'credits')}
            </div>
            <div className="userInfoMobile">
                {userCredits} {t('history', 'credits')}
            </div>
        </div>
    );
};



let prevPage = 1;

let t;

const HistoryScreen = () => {

    const getCreditsSum = () => {
        const creditsString = localStorage.getItem('credits');
        const credits = creditsString ? JSON.parse(creditsString) : { "GPT-3": 0, "GPT-4": 0 };
        return (credits["GPT-3"] || 0) + (credits["GPT-4"] || 0);
    };


    const [isOpen, setIsOpen] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState('carousel'); // Valor inicial é 'carousel'
    const [selectedStatus, setSelectedStatus] = useState('todos'); // Valor inicial para o filtro de status
    const [designId, setDesignId] = useState("");
    const [canvasUrl, setCanvasUrl] = useState("");
    const userName = localStorage.getItem('username');
    const userId = localStorage.getItem('userId');
    const location = useLocation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    const [userCredits, setUserCredits] = useState(getCreditsSum());
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
    const [historyData, setHistoryData] = useState([]);
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const navigate = useNavigate();
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const itemsPerPage = 20;
    const [loading, setLoading] = useState(false);
    const [loadingImages, setLoadingImages] = useState({});
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [tokenExpiriedFace, setTokenExpiriedFace] = useState(false);



    const fetchIdRef = useRef(0);



    const handleChange = (event) => {
        const isGPT4Enabled = event.target.checked;
        setIsGPT4Enabled(isGPT4Enabled);
        localStorage.setItem('isGPT4Enabled', isGPT4Enabled.toString());
    };



    useEffect(() => {
        // Reseta o estado do histórico e busca novamente os dados ao alterar o tipo de conteúdo
        setHistoryData([]);
        setAllDataLoaded(false);
        prevPage = 1;
        setSelectedStatus('todos'); // Reseta o filtro de status ao mudar de formato
        fetchUserHistory(1, selectedFormat, 'todos'); // Recarrega os dados com o novo formato e filtro de status

    }, [selectedFormat]); // Executa sempre que `selectedFormat` mudar


    useEffect(() => {

        const handleStorageChange = (e) => {
            if (e.key === 'language') {
                setLanguage(localStorage.getItem('language') || 'pt');
            }
        };

        const tokenExpiry = localStorage.getItem('tokenExpiry');

        if (!tokenExpiry || tokenExpiry === 'undefined' || tokenExpiry === 'null' || new Date(tokenExpiry) < new Date()) {
            // Instagram não está conectado
            setTokenExpiriedFace(true);
        } else {
            setTokenExpiriedFace(false);
        }

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

        // Verifica validade da conexão com Instagram

    }, []);

    t = (screen, key) => translations[language][screen][key];


    const handleHamburguerClick = () => {
        setIsOpen(true); // Abre o Drawer
    };

    const handleCloseDrawer = () => {
        setIsOpen(false); // Fecha o Drawer
    };

    const mainOpen = (language) => {
        navigate(`/main?lang=${language}`);
    }

    const analysisOpen = () => {
        navigate(`/analysis?lang=${language}`);
    }

    const perfilOpen = () => {
        navigate(`/viewuser?lang=${language}`);
    }

    const pciOpen = (language) => {
        navigate(`/pci?lang=${language}`);
    }

    const logout = (language) => {
        navigate(`/login?lang=${language}`);
    }

    useEffect(() => {
        return () => {
            // Resetar o estado aqui
            setHistoryData([]);
            setIsLoadingInitialData(true);
            prevPage = 1;
        };
    }, []);

    const [isGPT4Enabled, setIsGPT4Enabled] = useState(() => {
        const saved = localStorage.getItem('isGPT4Enabled');
        return saved === 'true'; // Retorna true se o valor armazenado for a string 'true'
    });


    async function editPost(presentationId, canvaDesingEditId) {
        try {
            // Primeiro, valida o token do Canva antes de prosseguir
            const haveTokenValid = await validateCanvaToken(userId);

            // Atualiza a flag de edição antes de prosseguir com a edição
            const flagResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/editFlag`, {
                presentationId,
                edit: '1'
            });

            if (flagResponse.status === 200) {
                // Só procede se o flag foi atualizado com sucesso

                setHistoryData(prevData =>
                    prevData.map(post =>
                        post.presentationId === presentationId
                            ? { ...post, editFlag: '1' } // Atualiza o editFlag localmente
                            : post
                    )
                );


                if (haveTokenValid) {
                    // Se o token for válido, executa a escolha de edição
                    handleEditChoice(presentationId, canvaDesingEditId);
                } else {
                    // Caso contrário, abre o documento no Google Presentation
                    window.open("https://docs.google.com/presentation/d/" + presentationId, '_blank');
                }
            } else {
                // Caso a atualização do flag falhe, lança um erro
                throw new Error(`Falha ao atualizar a flag de edição: ${flagResponse.data?.message || 'Erro desconhecido'}`);
            }
        } catch (error) {
            console.error('Erro ao editar post:', error);

            // Exibe uma mensagem de erro para o usuário
            Swal.fire({
                icon: 'error',
                title: t('history', 'postErro'),
                text: t('history', 'textErro'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    }

    async function validateCanvaToken(userId) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-canva-token`, {
                userId
            });
            return response.data.success; // Retorna true se o token é válido, false se não é válido
        } catch (error) {
            console.error('Erro ao validar token do Canva:', error);
            return false; // Retorna false em caso de erro na requisição
        }
    }


    const handleEditChoice = (presentationId, canvaDesingEditId) => {

        if (canvaDesingEditId) {

            handleCanvasConnect(presentationId, canvaDesingEditId);

        } else {

            Swal.fire({
                title: t('main', 'editCarouselTitle'),
                text: t('main', 'editCarouselText'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: t('main', 'editCarouselConfirmButton'),
                cancelButtonText: t('main', 'editCarouselCancelButton'),
                reverseButtons: true,
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle',
                    confirmButton: 'custom-confirm-button-class' // Classe customizada para o botão de confirmar
                },
                onOpen: () => {
                    // Aqui adicionamos o ícone ao botão depois que o Swal é aberto
                    const confirmButton = document.querySelector('.custom-confirm-button-class');
                    if (confirmButton) {
                        confirmButton.innerHTML = `<img src="https://www.canva.dev/assets/connect/1.svg" alt="Canva Icon" style="width: 16px; height: 16px; vertical-align: middle; margin-right: 5px;"> ${confirmButton.textContent}`;
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {

                    handleCanvasConnect(presentationId, canvaDesingEditId);


                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    window.open("https://docs.google.com/presentation/d/" + presentationId, '_blank');
                }
            });
        }

    };

    const handleCanvasConnect = async (presentationId, canvaDesingEditId) => {

        if (!canvaDesingEditId) {

            toast.info(
                t('main', 'openingCanva'), {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 6000 // Defina um tempo para o toast se fechar automaticamente após alguns segundos
            });

        }

        try {

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/edit-canvas`, {
                userId,
                presentationId
            });

            const { success, message, canvasUrl, designId } = response.data;

            if (success) {

                setDesignId(designId);
                setCanvasUrl(canvasUrl);

                // Atualiza o estado `historyData` para incluir o novo `designId`
                setHistoryData((prevData) =>
                    prevData.map((post) =>
                        post.presentationId === presentationId
                            ? { ...post, canvaDesingEditId: designId }
                            : post
                    )
                );


                if (!isMobile) {

                    window.open(canvasUrl, '_blank');

                } else {

                    Swal.fire({
                        title: t('main', 'confirmCanvaConnectionTitle'),
                        text: t('main', 'confirmCanvaConnectionText'),
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: t('main', 'confirmCanvaConnectionConfirmButton'),
                        cancelButtonText: t('main', 'confirmCanvaConnectionCancelButton'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }

                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(canvasUrl, '_blank');
                        }
                    });

                }

            } else {

                throw new Error(message);
            }
        } catch (error) {
            console.error('Erro ao conectar com o Canva:', error);
            Swal.fire({
                title: t('main', 'canvaConnectionErrorTitle'),
                text: t('main', 'canvaConnectionErrorText'),
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };

    const updateSlides = async (presentationId, contentId, canvaDesingEditId) => {
        try {


            setHistoryData((prevData) =>
                prevData.map((post) =>
                    post._id === contentId
                        ? { ...post, editFlag: '0' } // Seta editFlag para 0 imediatamente
                        : post
                )
            );


            setLoadingImages((prev) => ({ ...prev, [contentId]: true }));

            if (canvaDesingEditId) {
                // Atualiza design via Canva

                Swal.fire({
                    icon: 'warning',
                    title: t('history', 'attention'),
                    text: t('history', 'canvaAtention'),
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle',
                    },
                });

                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/export-design`, {
                        userId,
                        designId: canvaDesingEditId,
                        presentationId,
                    });

                    if (response.data.success) {
                        const updatedImageUrls = response.data.imageUrls;
                        const updatedCoverImage = updatedImageUrls.find(url => url.includes("image01"));
                        const updatedCoverImageWithTimestamp = `${updatedCoverImage}?timestamp=${new Date().getTime()}`;

                        // Atualiza a flag de edição para 0 e a imagem no estado `historyData`
                        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/editFlag`, {
                            presentationId,
                            edit: '0',
                        });

                        setHistoryData((prevData) =>
                            prevData.map((post) =>
                                post._id === contentId
                                    ? {
                                        ...post,
                                        azureImageUrls: [{ images: [updatedCoverImageWithTimestamp] }],
                                    }
                                    : post
                            )
                        );

                        Swal.fire({
                            icon: 'success',
                            title: t('main', 'success'),
                            text: t('main', 'successUpdatingSlides'),
                            confirmButtonText: 'Ok',
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle',
                            },
                        });
                    } else {
                        throw new Error(response.data.message || 'Erro ao exportar o design do Canva.');
                    }
                } catch (error) {
                    console.error('Erro ao importar design do Canva:', error);
                    Swal.fire({
                        icon: 'error',
                        title: t('history', 'errorTitle'),
                        text: t('history', 'errorCanva'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle',
                        },
                    });
                }
            } else {
                // Atualiza slides via Google Presentation
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/updateSlides`, {
                        userId: userId,
                        presentationId,
                        gpt4: isGPT4Enabled,
                    });

                    if (response.status === 200) {

                        Swal.fire({
                            icon: 'success',
                            title: t('main', 'success'),
                            text: t('main', 'successUpdatingSlides'),
                            confirmButtonText: 'Ok',
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle',
                            },
                        });


                        // Atualiza a flag de edição para 0 após a atualização
                        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/editFlag`, {
                            presentationId,
                            edit: '0',
                        });

                        const updatedCoverImage = `https://blob01instasuccess.blob.core.windows.net/carrossel-completo/image01_${presentationId}.jpg`;
                        const updatedCoverImageWithTimestamp = `${updatedCoverImage}?timestamp=${new Date().getTime()}`;

                        setTimeout(() => {
                            setHistoryData((prevData) =>
                                prevData.map((post) =>
                                    post._id === contentId
                                        ? { ...post, azureImageUrls: [{ images: [updatedCoverImageWithTimestamp] }] }
                                        : post
                                )
                            );


                        }, 2000); // Atraso de 2 segundos
                    } else {
                        throw new Error(response.data.message || 'Erro ao atualizar slides no Google Presentation.');
                    }
                } catch (error) {
                    console.error('Erro ao atualizar slides no Google Presentation:', error);
                    Swal.fire({
                        icon: 'error',
                        title: t('history', 'errorTitle'),
                        text: t('history', 'errorGoogle'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle',
                        },
                    });
                }
            }
        } catch (generalError) {
            console.error('Erro ao atualizar slides:', generalError);
            Swal.fire({
                icon: 'error',
                title: t('history', 'errorTitle'),
                text: t('history', 'errorSlide'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle',
                },
            });
        } finally {
            // Sempre removemos o spinner após tentativa de atualização, independentemente de erro ou sucesso
            setLoadingImages((prev) => ({ ...prev, [contentId]: false }));
        }
    };


    function totenIdToIndex(totenId) {
        const suffix = totenId.replace('perfil', ''); // Remove o prefixo 'perfil'
        const index = parseInt(suffix, 10) - 1; // Converte a parte numérica para integer e ajusta para base zero
        return index;
    }

    async function fetchUserHistory(currentPage, inputType = 'carousel', statusFilter = 'todos') {

        fetchIdRef.current += 1;
        const currentFetchId = fetchIdRef.current;

        if (currentPage === 1) {
            setIsLoadingInitialData(true);
        } else {
            setLoading(true); // Apenas exibe o estado de carregamento em segundo plano
        }

        const totenIndex = totenIdToIndex(localStorage.getItem('totenId'));
        const userId = localStorage.getItem('userId');

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/user-history`, {
                params: {
                    userId,
                    page: currentPage,
                    itemsPerPage,
                    inputType,
                    totenIndex
                }
            });

            if (response.status === 200) {

                const newData = response.data;

                const profileId = newData.length > 0 ? newData[0].profileId : null;
                let scheduleData = [];

                if (profileId) {
                    const scheduleResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/schedule-posts`, {
                        params: { userId, profileId }
                    });
                    if (scheduleResponse.status === 200) {
                        scheduleData = scheduleResponse.data;
                    }
                }

                // Combinar agendamentos e aplicar o filtro de status
                let combinedData = newData.map(post => {
                    const scheduledPost = scheduleData.find(sch => sch.contentId.toString() === post._id.toString());
                    if (scheduledPost) {
                        post.status = scheduledPost.status;
                        post.localScheduledTime = scheduledPost.localScheduledTime;
                    }

                    if (!scheduledPost || scheduledPost.status !== 'publicado') {
                        post.status = post.status || 'Não Agendado'; // Definir como 'Não Agendado' se não houver status
                    }

                    return post;
                });

                combinedData = combinedData.filter((post, index, self) =>
                    index === self.findIndex((t) => (
                        t._id.toString() === post._id.toString()
                    ))
                );

                if (statusFilter !== 'todos') {
                    combinedData = combinedData.filter(post => {
                        if (statusFilter === 'publicado') return post.status === 'publicado';
                        if (statusFilter === 'agendado') return post.status === 'agendado';
                        if (statusFilter === 'nAgendado') return !post.status || post.status === 'Não Agendado' || post.status === null || post.status === undefined;
                    });
                }

                if (currentFetchId === fetchIdRef.current) {
                    setHistoryData((prevData) => {
                        // Verificar se o item já está presente no estado anterior
                        const filteredData = combinedData.filter(
                            (newItem) => !prevData.some((existingItem) => existingItem._id === newItem._id)
                        );

                        // Adicionar apenas os itens que não estão presentes
                        return [...prevData, ...filteredData];
                    });

                    if (currentPage === 1) setIsLoadingInitialData(false);
                    if (combinedData.length < itemsPerPage) setAllDataLoaded(true);
                } else {
                    // Se não for a requisição mais recente, descarta os resultados
                    console.log('Descartando resultados de uma requisição anterior.');
                }
                if (newData.length < itemsPerPage) {
                    setAllDataLoaded(true); // Não há mais dados para carregar
                }

            } else {
                Swal.fire({ title: 'Erro', text: t('history', 'errorHist'), icon: 'error' });
            }
        } catch (error) {
            console.error('Erro ao buscar histórico de posts:', error);
        } finally {
            if (currentFetchId === fetchIdRef.current) {
                setLoading(false);
                setIsLoadingInitialData(false);
            }
        }
    }


    /* useEffect(() => {
         fetchUserHistory(prevPage, selectedFormat, selectedStatus);
     }, [userId, selectedStatus]); */


    useEffect(() => {
        // Carregar os dados iniciais quando a tela é carregada ou quando o formato ou status é alterado
        setIsLoadingInitialData(true); // Mostra o spinner inicial
        setHistoryData([]); // Limpa os dados anteriores
        setAllDataLoaded(false); // Permite o carregamento adicional
        prevPage = 1; // Reseta a página para o início
        fetchUserHistory(1, selectedFormat, selectedStatus);
    }, [selectedFormat, selectedStatus]); // Executa ao mudar o formato ou o status




    const handleStatusChange = (e) => {
        const status = e.target.value;
        setSelectedStatus(status); // Atualiza o status de filtro
        setHistoryData([]); // Limpa o histórico atual para evitar dados misturados
        setAllDataLoaded(false); // Permite novas buscas de dados
        prevPage = 1; // Reseta a página para começar do início
        fetchUserHistory(1, selectedFormat, status); // Busca os dados com o novo status e formato
    };


    const handleFormatChange = (format) => {
        setSelectedFormat(format); // Atualiza o estado do tipo de conteúdo
        setSelectedStatus('todos'); // Reseta o filtro de status ao mudar de formato
        setHistoryData([]); // Limpa os dados para evitar misturas
        setAllDataLoaded(false); // Permite novas buscas de dados
        prevPage = 1; // Reseta a página para começar do início
        fetchUserHistory(1, format, 'todos'); // Recarrega os dados com o novo formato e filtro padrão de status
    };



    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

        if (scrollHeight - scrollTop <= clientHeight + 5 && !loading && !allDataLoaded) {
            prevPage += 1;
            fetchUserHistory(prevPage); // Carrega mais posts sem mostrar o spinner
        }
    };


    async function handleCaptionClick(contentId, carousel, editCarousel) {
        try {
            // Consulta se a legenda já existe para o post
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-caption`, {
                params: {
                    contentId: contentId
                }
            });

            let caption = response.data.caption || ''; // Pega a legenda existente ou inicializa como vazia

            // Se a legenda não existir, avise o usuário sobre o desconto de créditos
            if (!caption) {
                const userChoice = localStorage.getItem('popupChoice_2');

                if (userChoice === 'checked') {
                    // Se o usuário já confirmou o uso de crédito anteriormente
                    caption = await generateCaption(contentId, carousel, editCarousel);

                } else {
                    // Se o usuário não marcou a opção "Não mostrar novamente"
                    const result = await Swal.fire({
                        title: t('main', 'confirmCreditUsage'),
                        showCancelButton: true,
                        confirmButtonText: t('main', 'yes'),
                        cancelButtonText: t('main', 'no'),
                        input: 'checkbox',
                        inputValue: 0,
                        inputPlaceholder: t('main', 'doNotShowAgain'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });

                    if (result.isConfirmed) {
                        // Se o usuário confirmou, gerar a legenda e salvar o crédito
                        if (result.value === 1) {
                            localStorage.setItem('popupChoice_2', 'checked');
                        }

                        caption = await generateCaption(contentId, carousel, editCarousel);

                    } else {
                        // Se o usuário cancelar, feche o processo
                        Swal.fire({
                            icon: 'info',
                            title: t('history', 'notCaption'),
                            text: t('history', 'credtRefauld'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                        return;
                    }
                }
            }

            // Fechar o popup de carregamento
            Swal.close();

            const { value: editedCaption } = await Swal.fire({
                title: t('history', 'editCaptionTitle'),
                input: "textarea",
                inputValue: caption,
                inputPlaceholder: t('history', 'editCaptionPlaceholder'),
                showCancelButton: true,
                confirmButtonText: t('history', 'confirmButtonText'),
                cancelButtonText: t('history', 'cancelButtonText'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                didOpen: () => {
                    const textarea = Swal.getInput(); // Obtém o textarea do SweetAlert
                    if (textarea) {
                        textarea.selectionStart = 0; // Define o cursor no início
                        textarea.selectionEnd = 0; // Define o cursor no início
                        textarea.scrollTop = 0; // Garante que o texto inicie na parte superior da caixa de texto
                    }
                }
            });

            // Se o usuário fez alterações e clicou em salvar
            if (editedCaption && editedCaption !== caption) {
                // Atualizar a legenda no servidor
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update-caption`, {
                    contentId: contentId,
                    caption: editedCaption
                });

                // Exibir uma mensagem de sucesso
                Swal.fire({
                    icon: 'success',
                    title: t('history', 'captionSavedTitle'),
                    text: t('history', 'captionSavedText'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });

                // Opcional: Atualizar o estado local, se necessário
                setHistoryData(prevData =>
                    prevData.map(post =>
                        post._id === contentId
                            ? { ...post, caption: editedCaption }
                            : post
                    )
                );
            }
        } catch (error) {
            // Lidar com qualquer erro que ocorrer
            Swal.fire({
                icon: 'error',
                title: t('history', 'errorTitle'),
                text: t('history', 'errorCaptionText'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
            console.error('Erro ao carregar ou salvar legenda:', error);
        }
    }

    // Função separada para gerar a legenda
    async function generateCaption(contentId, carousel, editCarousel) {
        // Exibe um popup indicando que a legenda está sendo carregada ou gerada
        Swal.fire({
            title: t('history', 'generatingCaptionTitle'),
            text: t('history', 'generatingCaptionText'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            }
        });

        // Chama a API para gerar a legenda
        const generateResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/caption`, {
            userId: localStorage.getItem('userId'),
            carousels: editCarousel ? editCarousel : carousel,
            savedUserHistoryId: contentId,
            gpt4: true, // Use GPT-4 ou GPT-3 dependendo das configurações
            language: localStorage.getItem('language') || 'pt' // Define o idioma
        });

        const caption = generateResponse.data.caption.choices[0].message.content;

        // Atualiza créditos no localStorage
        const newCredits = generateResponse.data.credits;
        localStorage.setItem('credits', JSON.stringify(newCredits));

        const creditAlert = generateResponse.data.creditAlert;
        if (creditAlert && creditAlert.shouldAlert) {
            displayCreditAlert(creditAlert.creditsRemaining);
        }

        return caption;
    }



    function displayCreditAlert(creditInfo) {

        Swal.fire({
            title: `${t('main', 'creditsRemainingPrefix')} ${creditInfo} ${t('main', 'creditsRemainingSuffix')}`,
            text: /*isNotProductionEnvironment() ? t('main', 'postSuccessWithDiscount') : */ t('main', 'doYouWantToBuyMoreCredits'),
            icon: 'warning',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            showCancelButton: true,
            confirmButtonText: t('main', 'yes'),
            cancelButtonText: t('main', 'no')
        }).then((result) => {
            /* if (result.isConfirmed && isNotProductionEnvironment()) {
                 // Se for confirmado e isUATEnvironment() for true, redirecione para o link de checkout da Eduzz
                 window.open('https://sun.eduzz.com/2105378?cupom=websummit0424', '_blank');
 
             } else */
            if (result.isConfirmed) {
                // Se for apenas confirmado (independente de isUATEnvironment()), chame displayCreditPurchaseOptions
                displayCreditPurchaseOptions();
            }
        });
    }

    function displayCreditPurchaseOptions() {
        Swal.fire({
            title: t('main', 'chooseCreditAmount'),
            input: 'radio',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            inputOptions: {
                '50': t('main', 'creditsOption50'),
                '100': t('main', 'creditsOption100'),
                '200': t('main', 'creditsOption200')
            },
            inputValidator: (value) => {
                if (!value) {
                    return t('main', 'mustChooseOption');
                }
            },
            confirmButtonText: t('main', 'buy')
        }).then((result) => {
            if (result.isConfirmed) {
                redirectToEduzzCheckout(result.value);
            }
        });
    }

    function redirectToEduzzCheckout(creditAmount) {
        // Redireciona o usuário para a página de checkout da Eduzz com a quantidade de créditos escolhida
        // Você precisará da URL de checkout da Eduzz para cada pacote de créditos
        const checkoutUrls = {
            '50': 'https://sun.eduzz.com/2230353',
            '100': 'https://sun.eduzz.com/2230366',
            '200': 'https://sun.eduzz.com/2230371'
        };

        window.open(checkoutUrls[creditAmount], '_blank');

    }

    async function agendamento(presentationId) {
        try {
            // Buscar o agendamento existente para o conteúdo
            const existingScheduleResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/getSchedulePost`, {
                params: { presentationId }
            });

            let existingSchedule = null;

            if (existingScheduleResponse.status === 200) {
                existingSchedule = existingScheduleResponse.data;
            }

            if (existingSchedule && existingSchedule.status === 'agendado') {
                // Se houver um agendamento, perguntar se o usuário deseja cancelá-lo
                const { value: confirmCancel } = await Swal.fire({
                    title: t('history', 'existingScheduleTitle'),
                    text: t('history', 'existingScheduleText'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('history', 'confirmButtonTextYes'),
                    cancelButtonText: t('history', 'cancelButtonTextNo')
                });

                if (confirmCancel) {
                    // Se o usuário deseja cancelar o agendamento, envie uma requisição de cancelamento
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/cancelSchedulePost`, {
                        contentId: existingSchedule.contentId,
                        status: 'cancelado',
                        userId: userId,
                        cancelReason: 'Cancelado pelo usuário'
                    });

                    // Atualizar o status do agendamento na interface
                    setHistoryData(prevData =>
                        prevData.map(post =>
                            post.presentationId === presentationId
                                ? { ...post, status: 'cancelado' }
                                : post
                        )
                    );

                    // Atualizar os créditos no localStorage com os dados da resposta do servidor
                    const newCredits = response.data.credits;
                    localStorage.setItem('credits', JSON.stringify(newCredits));

                    // return Swal.fire(t('history', 'cancelScheduleSuccessTitle'), t('history', 'cancelScheduleSuccessText'), 'success');

                    return Swal.fire({
                        title: t('history', 'cancelScheduleSuccessTitle'),
                        text: t('history', 'cancelScheduleSuccessText'),
                        icon: 'success',
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });

                }
            }

            if (!existingSchedule) {

                let userChoice = localStorage.getItem('popupChoice_3');

                if (userChoice !== 'checked') {

                    Swal.fire({
                        title: t('main', 'confirmCreditUsage'),
                        showCancelButton: true,
                        confirmButtonText: t('main', 'yes'),
                        cancelButtonText: t('main', 'no'),
                        input: 'checkbox',
                        inputValue: 0,
                        inputPlaceholder: t('main', 'doNotShowAgain'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (result.value === 1) {

                                localStorage.setItem('popupChoice_3', 'checked');

                            }
                        } else {

                            return;
                        }

                    });
                }
            }



            const { value: formValues } = await Swal.fire({
                title: t('main', 'agendarPost'),
                html: `
                  <style>
                    .swal2-popup .swal-custom-input, .swal2-popup .swal-custom-select {
                      width: 100%;
                      padding: 10px;
                      border-radius: 5px;
                      border: 1px solid #ccc;
                      margin-top: 10px;
                      font-size: 14px;
                    }
                    .swal2-popup .swal-custom-label {
                      font-weight: bold;
                      font-size: 14px;
                      margin-top: 10px;
                    }
                    .scrollable-container-pop-up {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      padding: 15px;
                    }
                  </style>
                  <div class="scrollable-container-pop-up">
                    <label for="scheduledTime" class="swal-custom-label">${t('main', 'dateHour')}</label>
                    <input type="datetime-local" id="scheduledTime" class="swal-custom-input" 
                           value="${existingSchedule ? moment(existingSchedule.localScheduledTime).format('YYYY-MM-DDTHH:mm') : ''}" />
                    
                    <label for="timezone" class="swal-custom-label">${t('main', 'dateFuso')}</label>
                    <select id="timezone" class="swal-custom-select">
                      <option value="America/Sao_Paulo" ${existingSchedule && existingSchedule.timezone === 'America/Sao_Paulo' ? 'selected' : ''}>${t('main', 'saoPaulo')}</option>
                      <option value="Europe/Lisbon" ${existingSchedule && existingSchedule.timezone === 'Europe/Lisbon' ? 'selected' : ''}>${t('main', 'lisboa')}</option>
                      <option value="Europe/Madrid" ${existingSchedule && existingSchedule.timezone === 'Europe/Madrid' ? 'selected' : ''}>${t('main', 'madrid')}</option>
                      <option value="Europe/Rome" ${existingSchedule && existingSchedule.timezone === 'Europe/Rome' ? 'selected' : ''}>${t('main', 'roma')}</option>
                    </select>
                  </div>
                `,
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonText: t('main', 'cancelPostButton'),
                confirmButtonText: t('main', 'confirmPostButton'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                preConfirm: () => {
                    const scheduledTime = document.getElementById('scheduledTime').value;
                    const timezone = document.getElementById('timezone').value;

                    console.log('Valor capturado de timezone:', timezone); // Log para verificação

                    if (!scheduledTime) {
                        Swal.showValidationMessage(t('main', 'pleaseDate'));
                        return false;
                    }

                    if (!timezone) {
                        Swal.showValidationMessage(t('main', 'pleaseFuso'));
                        return false;
                    }

                    return {
                        scheduledTime,
                        timezone
                    };
                }
            });

            if (formValues) {
                // Coletar valores do formulário
                const { scheduledTime, timezone } = formValues;

                const localMoment = moment.tz(scheduledTime, timezone);

                // Horário local (exatamente como o usuário inseriu)
                const localScheduledTime = localMoment.format('YYYY-MM-DDTHH:mm:ss');

                // Horário para o servidor (MongoDB converterá para UTC automaticamente)
                const serverScheduledTime = localMoment.toDate();

                // Enviar a requisição para agendar o post
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/schedulePost`, {
                    userId: localStorage.getItem('userId'),
                    totenId: totenIdToIndex(localStorage.getItem('totenId')),
                    presentationId,
                    localScheduledTime,
                    serverScheduledTime,
                    timezone,
                    status: 'agendado',
                    platform: 'Instagram'
                });

                // Atualizar o historyData com o novo status e horário, sem recarregar a página
                setHistoryData(prevData =>
                    prevData.map(post =>
                        post.presentationId === presentationId
                            ? { ...post, status: 'agendado', localScheduledTime }
                            : post
                    )
                );

                // Atualizar os créditos no localStorage a partir da resposta do servidor

                if (!existingSchedule || existingSchedule.status === 'cancelado') {

                    const newCredits = response.data.credits;
                    localStorage.setItem('credits', JSON.stringify(newCredits));

                }

                Swal.fire({
                    title: t('history', 'cancelScheduleSuccessTitle'),
                    text: t('main', 'pleaseSchedule'),
                    icon: 'success',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }

        } catch (error) {
            console.error(error);
            Swal.fire({
                title: t('history', 'errorTitle'),
                text: t('main', 'notPost'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    }

    const handleImageLoad = () => {
        setTimeout(() => {
            setIsImageLoaded(true); // Atualiza o estado para indicar que a imagem foi carregada
        }, 1000);
    };

    return (
        <div className="history-screen">
            <ChakraProvider>
                <CustomDrawer
                    isOpen={isOpen}
                    onClose={handleCloseDrawer}
                    handleChange={handleChange}
                    onAnalysisOpen={() => analysisOpen(language)}
                    isGPT4Enabled={isGPT4Enabled}
                    onContentOpen={() => mainOpen(language)}
                    onPerfilOpen={() => perfilOpen(language)}
                    onPCI={() => pciOpen(language)}
                    onLogout={() => logout(language)}
                    t={t}
                    language={language}
                    setLanguage={setLanguage}
                />
            </ChakraProvider>
            <div className="header_history" >
                <UserInfo
                    userName={userName}
                    userId={userId}
                    userCredits={userCredits}
                />
                <div className="image-container_history_01">
                    <MdMenu
                        className="menu-icon"
                        onClick={handleHamburguerClick}
                    />
                </div>
                <div className="image-container_history_02">
                    <img
                        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                        alt="Logo"
                        className="logo"
                    />
                </div>
            </div>

            <div className="divAreaImageHistory" >
                {/* Título da página sempre renderizado */}
                <h2 className="categoryTitleHistory">{t('history', 'creationHistory')}</h2>
                <div className="buttonGroupImgHist">
                    <ChakraProvider>
                        <RadioGroup
                            className='positionRadio'
                            onChange={(value) => handleFormatChange(value)} // Substitui a chamada direta de `fetchUserHistory`
                            value={selectedFormat}
                        >
                            <Stack direction='row' spacing={4} >
                                <Radio value="carousel" colorScheme="purple">{t('main', 'carrossel')}</Radio>
                                <Radio value="stories" colorScheme="purple">{t('main', 'stories')}</Radio>
                            </Stack>
                        </RadioGroup>
                    </ChakraProvider>
                    <ChakraProvider>
                        {/*<Stack className="stk" backgroundColor='purple' alignItems='center'  spacing={4} direction='row'> */}
                        <Select
                            className='slcSlidesLayHis'
                            size='sm'
                            color={'#757575'}
                            marginLeft={{ base: '0px', md: '20px' }}
                            width={{ base: '117.5px', md: '160px' }}
                            ml={{ base: -1, md: 1 }}
                            value={selectedStatus}
                            onChange={handleStatusChange} // Alterado aqui
                        >
                            <option value="todos">{t('history', 'todos')}</option>
                            <option value="publicado">{t('history', 'publicado')}</option>
                            <option value="agendado">{t('history', 'agendado')}</option>
                            <option value="nAgendado">{t('history', 'nAgendado')}</option>

                        </Select>
                    </ChakraProvider>
                </div>

                <div className="images-grid" onScroll={handleScroll}>
                    {isLoadingInitialData ? (
                        // Exibe o spinner apenas durante o carregamento inicial
                        <div className="spinner"></div>
                    ) : (
                        historyData.length === 0 ? (
                            // Mensagem de "Nenhum post encontrado" se não houver posts após o carregamento
                            <div className="no-data-placeholder">
                                <span>{t('history', 'noPostsFound')}</span>
                            </div>
                        ) : (

                            historyData.map((item, index) => {
                                console.log('editFlag:', item.editFlag);
                                if (!item.azureImageUrls || !item.azureImageUrls[0]) return null;
                                // Verificar se item.azureImageUrls[0].images é um array antes de acessar o .find()
                                const imagesArray = Array.isArray(item.azureImageUrls[0].images) ? item.azureImageUrls[0].images : [];
                                const coverImage = item.azureImageUrls[0].images.find(url => url.includes("image01"))
                                    ? `${item.azureImageUrls[0].images.find(url => url.includes("image01"))}?timestamp=${new Date().getTime()}`
                                    : null;

                                // Se coverImage não for encontrado, retorna null para evitar erros
                                if (!coverImage) return null;

                                const sessionStartDate = new Date(item.sessionStart).toLocaleDateString('pt-BR') + ' ' + new Date(item.sessionStart).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

                                // Definir o status e a cor da bolinha com base no status do agendamento
                                let statusText = `${t('history', 'notScheduled')}`;
                                let statusColor = 'gray';

                                if (item.status === 'agendado') {
                                    const formattedDate = moment(item.localScheduledTime).format('DD/MM/YYYY HH:mm'); // Formatação desejada
                                    statusText = `${t('history', 'scheduled')} | ${formattedDate}`;
                                    statusColor = 'orange';
                                } else if (item.status === 'publicado') {
                                    const formattedDate = moment(item.localScheduledTime).format('DD/MM/YYYY HH:mm');
                                    statusText = `${t('history', 'published')} | ${formattedDate}`;
                                    statusColor = 'green';
                                }

                                return (
                                    <div
                                        key={`${item._id}-${index}`}
                                        className={`post-container ${item.editFlag === '1' ? 'editing' : ''}`}
                                    >

                                        {/* Indicador de Status com Bolinha e Texto */}
                                        <div className="status-container">
                                            <span className="status-indicator" style={{ backgroundColor: statusColor }}></span>
                                            <span className="status-text">{statusText}</span>
                                        </div>

                                        {/* Div para a imagem, que agora vai mostrar um spinner durante a atualização */}
                                        <div className={`image-wrapper ${selectedFormat === 'stories' ? 'stories-size' : ''}`}
                                            onClick={() => updateSlides(item.presentationId, item._id, item.canvaDesingEditId)}
                                            onMouseOver={(e) => {
                                                if (!('ontouchstart' in window) && item.editFlag !== '1') {
                                                    e.currentTarget.querySelector('.overlay').style.opacity = 0.5;
                                                }
                                            }}
                                            onMouseOut={(e) => {
                                                if (!('ontouchstart' in window) && item.editFlag !== '1') {
                                                    e.currentTarget.querySelector('.overlay').style.opacity = 0;
                                                }
                                            }}
                                            style={{ cursor: 'pointer' }} // Adiciona o cursor de pointer para indicar que é clicável
                                        >
                                            {/* Mostrar spinner ou imagem dependendo do estado de carregamento */}
                                            {
                                                coverImage ? (
                                                    <img
                                                        src={`${coverImage}`}
                                                        alt={'Capa do carrossel'}
                                                        className="history-image"
                                                        onLoad={handleImageLoad}
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        onError={(e) => e.target.src = 'fallback_image_url.png'}
                                                    />
                                                ) : (
                                                    <div className="image-placeholder">{t('history', 'imageNotAvailable')}</div>
                                                )
                                            }

                                            {/* Overlay com a lógica de visibilidade */}

                                            <div className={`overlay ${loadingImages[item._id] ? 'updating' : ''}`}

                                                style={{
                                                    opacity: item.editFlag === '1' ? 1 : 0, // Sempre visível se editFlag for '1'
                                                    pointerEvents: item.editFlag === '1' ? 'none' : 'auto', // Desabilitar clique na overlay
                                                }}

                                            >
                                                {/* Ícone SVG do lado esquerdo */}

                                                {loadingImages[item._id] ? (
                                                    <span>{t('history', 'updating')}</span>
                                                ) : (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="24px" height="24px" style={{ marginRight: '0px' }}>
                                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm0-4h-2V7h2v8z" />
                                                        </svg>
                                                        <span style={{ color: 'white', opacity: 1 }}>{t('history', 'update')}</span>
                                                    </>
                                                )}
                                            </div>


                                            {isImageLoaded && (
                                                <div className="date-label">
                                                    {`${t('history', 'created')}: ${sessionStartDate}`}
                                                </div>
                                            )}
                                        </div>

                                        {/* Contêiner dos Botões - Abaixo da Imagem */}
                                        <div className="buttons-container">
                                            {/* Botões lado a lado */}
                                            <button className="action-button" title={t('history', 'edit')} onClick={() => editPost(item.presentationId, item.canvaDesingEditId)}><MdEdit className="buttonIcon" /></button>

                                            {selectedFormat !== 'stories' && (
                                                <button className="action-button" title={t('history', 'caption')} onClick={() => handleCaptionClick(item._id, item.carousel, item.editedCarousel)} ><MdKeyboard className="buttonIcon" /></button>
                                            )}

                                            {!tokenExpiriedFace && (
                                                <button className="action-button" title={t('history', 'schedule')} onClick={() => agendamento(item.presentationId)} ><MdDateRange className="buttonIcon" /></button>
                                            )}

                                        </div>
                                    </div>
                                );
                            })
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default HistoryScreen;
