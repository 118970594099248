// ImageSelector.js

import React from 'react';
import { MdAutorenew, MdFileUpload } from "react-icons/md";
import { ChakraProvider, RadioGroup, Radio, Stack } from "@chakra-ui/react";

function ImageSelector({ onSearchTermChange, onHandlePointerMove, onNewImagesClick, thumbnailImages, onImageSelected, searchTerm, text, isLoading, selectedImage, onSourceTypeChange, imageSourceType, onFileUpload, isMob, selectedOwnImage, slideId, t }) {
    return (
        <div className="divAreaImage">
            <div className="buttonGroupImg">
                <textarea value={text} className={`inputAreaTxt ${imageSourceType === 'own' ? 'inputAreaTxtFullWidth' : ''}`} />
                <div className="btnNew" hidden={imageSourceType === 'own'}>
                    <input
                        type="text"
                        onChange={onSearchTermChange}
                        value={searchTerm}
                        className='inputFocusTxt'
                        placeholder= {t('main','searchPlaceholder')}
                        onPointerMove={onHandlePointerMove}
                        onFocus={(e) => e.target.value === e.target.defaultValue && (e.target.value = '')}
                    />
                    <button
                        className="googleButton buttonStyle"
                        style={{ width: '160px', marginTop: '0px' }}
                        onClick={onNewImagesClick}
                    >
                        <span className="buttonContent"> <MdAutorenew className="buttonIcon" /> {t('main','newImages')}</span>
                    </button>
                </div>
            </div>
            <div className={`imagesContainer ${imageSourceType === 'own' ? 'imagesOwn' : 'imagesBank'}`}>
                {isLoading ? (
                    <div className="spinner_4"></div> // Mostra o spinner se estiver carregando
                ) : imageSourceType === "bank" ? (
                    thumbnailImages.map((thumbnailUrl, index) => (
                        <div
                            key={index}
                            className={`divImagesAjs ${thumbnailUrl.full === selectedImage ? "selected" : ""}`}
                            onMouseOver={(e) => e.currentTarget.querySelector('.overlay').style.opacity = 0.5}
                            onMouseOut={(e) => e.currentTarget.querySelector('.overlay').style.opacity = 0}
                            onClick={() => onImageSelected(thumbnailUrl)}
                        >
                            <img
                                src={thumbnailUrl.thumbnail}
                                alt={`Thumbnail ${index + 1}`}
                                className="thumbnailImage"
                            />

                            <div className="overlay" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'black',
                                opacity: 0,
                                transition: 'opacity 0.3s'
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '30%',
                                transform: 'translateX(-20%)',
                                color: 'white',
                                fontSize: '10px',
                                textAlign: 'center'
                            }}

                                hidden={thumbnailUrl.thumbnail && !thumbnailUrl.photographer}

                            >
                                <span> This </span>
                                <a
                                    href={thumbnailUrl.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                    onClick={e => e.stopPropagation()} // Adicione esta linha
                                >
                                    Photo
                                </a>
                                <span> was taken by </span>
                                <a
                                    href={thumbnailUrl.photographer_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                    onClick={e => e.stopPropagation()} // Adicione esta linha
                                >
                                    {thumbnailUrl.photographer}
                                </a>
                                <span> on Pexels.</span>
                            </div>
                        </div>
                    ))) : (
                    <div className="uploadContainer">
                        <div className="imagePreviewArea">
                            {isLoading ? (
                                <div className="spinner_4"></div> // Substitua pelo spinner de sua preferência
                            ) : (
                                <>
                                    <img className='imagePreview' alt="Preview" src={selectedOwnImage} />
                                    <div className="fileSizeNote">{t('main', 'maxFileSize')}</div>
                                </>
                            )}
                        </div>
                        <div className="fileInputContainer">
                            <input type="file" id={`customFileInput-${slideId}`} onChange={onFileUpload} className="fileInput" hidden/>
                            <label htmlFor={`customFileInput-${slideId}`} className="customFileButton">
                                <span> <MdFileUpload className="buttonIcon" /></span>{t('main','chooseFile')}
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <div className="radio-group-footer">
                {/* Aqui vai o componente com os RadioGroups */}
                <ChakraProvider>
                    <RadioGroup onChange={onSourceTypeChange} value={imageSourceType} >
                        <Stack direction='row'>
                            <Radio value="bank" colorScheme="purple">{isMob ? t('main', 'imageBankShort') : t('main', 'imageBank')}</Radio>
                            <Radio value="own" colorScheme="purple">{isMob ? t('main', 'ownImagesShort') : t('main', 'ownImages')}</Radio>
                        </Stack>
                    </RadioGroup>
                </ChakraProvider>
            </div>
        </div>
    );
}

export default ImageSelector;
