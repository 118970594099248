import React, { useEffect, useState } from "react";
import './ResetPasswordRequest.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/theme-material-ui/material-ui.css';
import translations from '../utils/translations.json';
import { useLocation } from 'react-router-dom';

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState("");

    const location = useLocation();
    // const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    const [language, setLanguage] = useState(localStorage.getItem('language') || navigator.language.slice(0, 2));

        useEffect(() => {
            const params = new URLSearchParams(location.search);
            const langFromUrl = params.get('lang');
            if (langFromUrl && langFromUrl !== language) {
                setLanguage(langFromUrl);
                localStorage.setItem('language', langFromUrl);
            }
        }, [location, language]);
    
    
       const t = (screen, key) => translations[language][screen][key];

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {
            email: email,
            language: language
        }

        axios.post(process.env.REACT_APP_API_BASE_URL + '/passwordReset/resetpasswordrequest', user)
            .then(response => {
                console.log(response);
                Swal.fire({
                    title: t('resetPasswordRequest', 'successTitle'),
                    text: t('resetPasswordRequest', 'successText'),
                    icon: 'success',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                }); // informa ao usuário para verificar seu email
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    title: t('resetPasswordRequest', 'errorTitle'),
                    text: t('resetPasswordRequest', 'internalServerError'),
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            });
    }

    return (
        <div className="resetpasswordrequest-form">
            <div className="logo-container">
                <img
                    src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                    alt="Logo"
                    className="logo-image"
                />
            </div>
            <form onSubmit={handleSubmit}>
                <label style={{ color: '#757575' }}>
                {t('resetPasswordRequest', 'emailLabel')}
                    <input type="email" value={email} className='textImput' onChange={e => setEmail(e.target.value)} />
                </label>
                <input className="submit-button" type="submit" value={t('resetPasswordRequest', 'submitButtonText')} />
                <h5 style={{ textAlign: "center", marginTop: "15px", color: '#757575' }}>
                    <Link style={{ color: '#757575' }} to={`/login?lang=${language}`}>{t('resetPasswordRequest', 'rememberPassword')}</Link>
                </h5>
            </form>
        </div>
    );
}

export default ResetPasswordRequest;
