import React from 'react';
import { useLocation } from 'react-router-dom';
import ChatbotBubble from '../utils/chatbase';

const ChatbotController = () => {
  const location = useLocation();

  // Função para detectar se o dispositivo é mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Só renderiza o ChatbotBubble se não for mobile e estiver na rota '/main'
  return !isMobile && location.pathname === '/main' ? <ChatbotBubble /> : null;
  
};

export default ChatbotController;