// categories.js

const categories = [
    { name: 'anxiety', translation: 'Anciedade' },
    { name: 'headline', translation: 'Título' },
    { name: 'carousel', translation: 'Carrossel' },
    { name: 'image', translation: 'Imagem' }

];

export default categories;
