import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import translations from '../utils/translations.json';
import CustomDrawer from './CustomDrawer';
import { ChakraProvider } from "@chakra-ui/react";
import { MdMenu } from "react-icons/md";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import Swal from 'sweetalert2';
import './PrivacyPolicy.css';



const loadMarkdown = async (language) => {
    const filePath = language === 'pt' ? '/privacy_policy_pt.md' : '/privacy_policy_en.md';
    const response = await fetch(filePath);
    const text = await response.text();
    return text;
};


const PrivacyPolicy = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const supportedLanguages = ['pt', 'en']; // Adicione mais códigos de idioma conforme necessário
    const defaultLanguage = 'en'; // Define inglês como idioma padrão

    // Define o idioma com base no navegador ou retorna ao padrão se não suportado
    const browserLanguage = navigator.language.slice(0, 2);
    const initialLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : defaultLanguage;

    const [language, setLanguage] = useState(localStorage.getItem('language') || initialLanguage);
    const t = (screen, key) => translations[language][screen][key];
    const [isOpen, setIsOpen] = useState(false);
    const [policyText, setPolicyText] = useState('');

    // Verificar o parâmetro 'lang' na URL e definir a linguagem com base nele
    const params = new URLSearchParams(location.search);
    const langFromUrl = params.get('lang');
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        if (langFromUrl && langFromUrl !== language) {
            setLanguage(langFromUrl);
            localStorage.setItem('language', langFromUrl);
        }
    }, [langFromUrl, language]);


    useEffect(() => {
        const fetchPolicyText = async () => {
            const text = await loadMarkdown(language);
            setPolicyText(text);
        };
        fetchPolicyText();
    }, [language]);



    const handleHamburguerClick = () => {
        if (userId) {
            setIsOpen(true); // Abre o Drawer
        } else {
            // Exibe uma mensagem de alerta se o usuário não estiver logado
            Swal.fire({
                icon: 'warning',
                title: t('privacyPolicy', 'notLoggedInTitle'), // Ajuste conforme suas traduções
                text: t('privacyPolicy', 'notLoggedInMessage'), // Ajuste conforme suas traduções
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };

    const handleCloseDrawer = () => {
        setIsOpen(false); // Fecha o Drawer
    };

    const handleLanguageChange = (newLanguage) => {
        if (newLanguage !== language) {
            setLanguage(newLanguage);
            localStorage.setItem('language', newLanguage);
            navigate(`${location.pathname}?lang=${newLanguage}`);
        }
    };


    const perfilOpen = () => {
        navigate(`/viewuser?lang=${language}`);
    }

    const historyOpen = (language) => {
        navigate(`/history?lang=${language}`);
    }

    const logout = (language) => {
        navigate(`/login?lang=${language}`);
    }

    const mainOpen = (language) => {
        navigate(`/main?lang=${language}`);
    }

    const pciOpen = (language) => {
        navigate(`/pci?lang=${language}`);
    }

    return (

        <div className="privacy-policy">
            <ChakraProvider>
                <CustomDrawer
                    isOpen={isOpen}
                    onClose={handleCloseDrawer}
                    onPerfilOpen={() => perfilOpen(language)}
                    onContentOpen={() => mainOpen(language)}
                    onPCI={() => pciOpen(language)}
                    onLogout={() => logout(language)}
                    onHistoryOpen={() => historyOpen(language)}
                    t={t}
                    language={language}
                    setLanguage={handleLanguageChange}
                    isCanvaConnected={localStorage.getItem('accessToken')}
                />
            </ChakraProvider>

            <div className="image-container_01" style={{ backgroundColor: 'white', width: 50, height: 42, zIndex: 5 }}>
                <MdMenu
                    className="menu-icon"
                    onClick={handleHamburguerClick}
                />


            </div>

            <div className="header_form_privacy">
                <div className="logo-container-form-privacy">
                    <img
                        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                        alt="Logo"
                        className="logo-image-form"
                    />
                </div>
            </div>

            <h2 className="categoryPrivacy">{t('privacyPolicy', 'title')}</h2>

            <div className="textPrivacyPolicy">
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    children={policyText}
                />
            </div>
        </div>
    );
}

export default PrivacyPolicy;








