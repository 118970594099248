

export const colorCombinations = [
        { comboId: 'combo1', colors: ['#FFFFFF', '#FFFFFF', '#000000'], backgroundId: '#' },
        { comboId: 'combo2', colors: ['#000000', '#000000', '#FFFFFF'], backgroundId: '#' },
        { comboId: 'combo3', colors: ['#173D4D', '#33AF9E', '#E1F0F1'], backgroundId: '#' },
        { comboId: 'combo4', colors: ['#E74B3B', '#2C3D4F', '#F2F3F4'], backgroundId: '#' },
        { comboId: 'combo5', colors: ['#6C8468', '#97A489', '#F6FFE4'], backgroundId: '#' },
        { comboId: 'combo6', colors: ['#39A6B8', '#81A39D', '#F6FFFD'], backgroundId: '#' },
        { comboId: 'combo7', colors: ['#001C2F', '#176A87', '#F5FEFE'], backgroundId: '#' },
        { comboId: 'combo8', colors: ['#FF6665', '#FE8989', '#FFF8F3'], backgroundId: '#' },
        { comboId: 'combo9', colors: ['#3F2305', '#B4AE9B', '#F5F5F5'], backgroundId: '#' },
        { comboId: 'combo10', colors: ['#3C1D4E', '#9558A1', '#F7F2F9'], backgroundId: '#' },
        { comboId: 'combo11', colors: ['#A63E44', '#966D62', '#FDFDFD'], backgroundId: '#' },
        { comboId: 'combo12', colors: ['#2C3B50', '#638CB2', '#F3F6F9'], backgroundId: '#' },
        { comboId: 'combo13', colors: ['#4A4FC7', '#787B96', '#FFFFFF'], backgroundId: '#' },
        { comboId: 'combo14', colors: ['#6D1D54', '#A63E6D', '#F7F4F8'], backgroundId: '#' },
        { comboId: 'combo15', colors: ['#4E9C6A', '#EDA53B', '#F9F4EB'], backgroundId: '#' },
        { comboId: 'combo16', colors: ['#A57239', '#5B776B', '#FEF9F2'], backgroundId: '#' },
        { comboId: 'combo17', colors: ['#8E6E1D', '#C7A442', '#FAF8EC'], backgroundId: '#' },
        { comboId: 'combo18', colors: ['#B32824', '#52646F', '#F8F9FA'], backgroundId: '#' },
        { comboId: 'combo19', colors: ['#4A55A2', '#7895CB', '#ECF6FF'], backgroundId: '#' },
        { comboId: 'combo20', colors: ['#7A3B38', '#B9817B', '#FEF4F5'], backgroundId: '#' }
    ];


    export const colorCombinationsStories = [

        { comboId: 'combo1', colors: ['#FFFFFF', '#000000'], backgroundId: 'background1' }, // Fundo preto
        { comboId: 'combo2', colors: ['#FFFFFF', '#FFA500'], backgroundId: 'background2'  }, // Fundo laranja
        { comboId: 'combo3', colors: ['#FFFFFF', '#FF1493'], backgroundId: 'background3'  }, // Fundo rosa
        { comboId: 'combo4', colors: ['#FFFFFF', '#8A2BE2'], backgroundId: 'background4'  }, // Fundo roxo
        { comboId: 'combo5', colors: ['#FFFFFF', '#32CD32'], backgroundId: 'background5' }, // Fundo verde
        { comboId: 'combo6', colors: ['#FFFFFF', 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)'], backgroundId: 'background6'  }, // Fundo arco-íris (primeiro)
        { comboId: 'combo7', colors: ['#000000', 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)'], backgroundId: 'background6'  }, // Fundo arco-íris (segundo)
        { comboId: 'combo8', colors: ['#000000', '#F5F5F5'], backgroundId: 'background7'  }, // Fundo branco
        
    ];
    



