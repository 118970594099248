import React from 'react';
import './LandscapeWarning.css';

const LandscapeWarning = () => {
    const [showWarning, setShowWarning] = React.useState(false);

    // Verifica se o dispositivo é um tablet
    const isTabletDevice = () => {
        return /iPad|Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent);
    };

    // Verifica se o dispositivo é um celular
    const isMobileDevice = () => {
        return /Mobile|iP(hone|od)|Android/i.test(navigator.userAgent);
    };

    React.useEffect(() => {
        const checkOrientation = () => {
            const isTablet = isTabletDevice();
            const isMobile = isMobileDevice();
            const isLandscape = window.innerWidth > window.innerHeight;

            let shouldShowWarning = false;

            if (isTablet) {
                shouldShowWarning = !isLandscape;  // Tablets devem usar paisagem
            } else if (isMobile) {
                shouldShowWarning = isLandscape;  // Celulares devem usar retrato
            }

            if (showWarning !== shouldShowWarning) {
                setShowWarning(shouldShowWarning);
                if (shouldShowWarning) {
                    window.scrollTo(0, 0);  // Ajuste a tela para o topo se mostrar aviso
                }
            }
        };

        // Adiciona o ouvinte ao montar o componente
        window.addEventListener("resize", checkOrientation);

        // Verifica a orientação inicial
        checkOrientation();

        // Remove o ouvinte ao desmontar o componente
        return () => {
            window.removeEventListener("resize", checkOrientation);
        };
    }, [showWarning]);

    return showWarning ? (
        <div className="landscape-warning">
            <h1>Atenção</h1>
            <h2>{isTabletDevice() ? 'Vire seu dispositivo para o modo paisagem.' : 'Vire seu dispositivo para o modo retrato.'}</h2>
        </div>
    ) : null;
};

export default LandscapeWarning;
